<template lang="html">
  <section v-show="loadedInvitation">
    <section v-if="invitation">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="Card text-md text-bold mb-24">
          We found your invitation to {{ invitation.team.name }}!
        </div>
        <form @submit.prevent="handleSubmit(handleSubmission)" class="Form">
          <div class="Form__wrapper">
            <h1 class="title-3">Register</h1>
            <p class="text-xlg text-semi">
              Complete the signup form to respond to your invitation
            </p>
            <formly-form
              :form="form"
              :model="model"
              :fields="fields"
              tag="div"
              class="Form__group"
            ></formly-form>
          </div>
          <button class="btn btn--primary btn--min">Sign Up</button>
        </form>
        <router-link
          :to="{ name: 'login' }"
          class="clickable nav-link inline-block mt-16 text-md"
          >Log in!</router-link
        >
      </ValidationObserver>
    </section>
    <section v-else>
      <div class="Card Card--danger text-md text-bold mb-24">
        Invitation not found!
      </div>
      <SignUp></SignUp>
    </section>
  </section>
</template>

<script>
  import SignUp from './SignUp'
  import api from '@/api/index'

  export default {
    name: 'Register',
    components: { SignUp },
    data: function() {
      return {
        token: null,
        loadedInvitation: false,
        invitation: false,
        model: {
          name: null,
          email: null,
          username: null,
          password: null,
          password_confirmation: null,
        },
        form: {},
        fields: [
          {
            key: 'name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Full Name',
              layout: 'half',
            },
          },
          {
            key: 'username',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Username',
              layout: 'half',
            },
          },
          {
            key: 'email',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'email',
              label: 'Email Address',
              layout: 'half',
            },
          },
          {
            key: 'password',
            type: 'input',
            rules: 'required|password:@password_confirmation',
            templateOptions: {
              type: 'password',
              label: 'Password',
              layout: 'full',
            },
          },
          {
            key: 'password_confirmation',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'password',
              label: 'Repeat Password',
              layout: 'full',
            },
          },
        ],
      }
    },
    created() {
      let invitationID = this.$route.query.invitation
      this.token = invitationID ? invitationID : false
    },
    mounted() {
      if (this.token) {
        api.http
          .get(`register/${this.token}`)
          .then((res) => {
            this.invitation = res.body
            this.loadedInvitation = true
          })
          .catch(() => {
            this.invitation = false
            this.loadedInvitation = true
          })
      } else {
        this.invitation = false
        this.loadedInvitation = true
      }
    },
    methods: {
      handleSubmission() {
        this.$store
          .dispatch('AUTH_REQUEST', {
            action: 'register',
            ...this.model,
            invitation_token: this.token,
            account_type: 'user',
          })
          .then(() => {
            this.$router.push({ name: 'events' })
          })
      },
    },
  }
</script>
