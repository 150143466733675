<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(handleSubmission)" class="Form">
      <div class="Form__wrapper">
        <h1 class="title-3">Create New Club Account</h1>
        <p class="text-xlg text-semi">
          Complete the signup form to create a New Club Account
        </p>
        <p class="text-semi">
          Important: if you have been invited to be a selector for an event
          please use the link provided by your administrator. Do not sign up
          here!
        </p>
        <formly-form
          :form="form"
          :model="model"
          :fields="fields"
          tag="div"
          class="Form__group"
        ></formly-form>
      </div>
      <button class="btn btn--primary btn--min">Sign Up</button>
    </form>
    <router-link
      :to="{ name: 'login' }"
      class="clickable nav-link inline-block mt-16 text-md"
      >Already registered? Log in!</router-link
    >
  </ValidationObserver>
</template>

<script>
  export default {
    name: 'Register',
    data: function() {
      return {
        model: {
          name: null,
          username: null,
          club_name: null,
          email: null,
          password: null,
          password_confirmation: null,
        },
        form: {},
        fields: [
          {
            key: 'club_name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Netball club name',
              layout: 'full',
            },
          },
          {
            key: 'name',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Full Name',
              layout: 'full',
            },
          },
          {
            key: 'username',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Username',
              layout: 'half',
            },
          },
          {
            key: 'email',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'email',
              label: 'Email Address',
              layout: 'half',
            },
          },
          {
            key: 'password',
            type: 'input',
            rules: 'required|password:@password_confirmation',
            templateOptions: {
              type: 'password',
              label: 'Password',
              layout: 'half',
            },
          },
          {
            key: 'password_confirmation',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'password',
              label: 'Repeat Password',
              layout: 'half',
            },
          },
        ],
      }
    },
    methods: {
      handleSubmission() {
        this.$store
          .dispatch('AUTH_REQUEST', {
            action: 'register',
            account_type: 'user',
            ...this.model,
          })
          .then(() => {
            this.$router.push({ name: 'events' })
          })
      },
    },
  }
</script>
